import { indexToLocaleKey, locales } from "i18n";
import generatorsData from "../../../generators/generators.json";
import tagsData from "../../../generators/tags.json";

interface Generator {
  id: string;
  altId: string[];
  cfgU: number;
  cfgM: number;
  cfgL: number;
  styleName: { name: string; value: string }[];
  styleImage: string;
}

interface Tag {
  id: string;
  names: { [key: string]: string };
}

// todo dont export, should export a localized cached version and pass to context in SSG
export const generators: Generator[] = generatorsData as Generator[];
const allTags: Tag[] = tagsData.tags as Tag[];

// Check if the index is valid and provide a fallback
export function getLocaleKeyByIndex(index: number): string {
  if (index >= 0 && index <= 9) {
    return indexToLocaleKey[index];
  }
  return indexToLocaleKey[0]; // Default to English
}

// DEFINE MAPS FOR LOOKUP FUNCTIONS

// Map from altId to index
const altIdToIndexMap = new Map<string, number>();
// Map from short name (.id) to locale to style name
const idToNameMap = new Map<string, Map<string, string>>();

// Map from object ID (.altId) to short name (.id)
const altIdToIdMap = new Map<string, string>();

// Map from short name (.id) to object ID (.altId)
const idToAltIdMap = new Map<string, string>();

// Map for tag names: tagId -> locale -> name
// If not found here, then it's probably a UGC tag
const tagNameMap = new Map<string, Map<string, string>>();

// BUILD LOOKUP MAPS

// Initialize maps at build time
let index = -1;
for (const generator of generators) {
  index++;
  const altId = generator.altId[0];
  if (!altId) continue;
  altIdToIndexMap.set(altId, index);
  // Map object ID to short name
  altIdToIdMap.set(altId, generator.id);

  // Map short name to object ID
  idToAltIdMap.set(generator.id, altId);

  // Create style map: id → locale → style name
  const localeToStyleMap = new Map<string, string>();
  for (const style of generator.styleName) {
    localeToStyleMap.set(style.name, style.value);
  }
  idToNameMap.set(generator.id, localeToStyleMap);

  // Tag name map (flattened structure for O(1) lookup)
  for (const tag of allTags) {
    if (tagNameMap.has(tag.id)) continue;
    const localeMap = new Map<string, string>();
    // Loop through all supported locales from indexToLocaleKey
    Object.values(indexToLocaleKey).forEach((localeCode) => {
      const tagName: string = tag.names[localeCode] || tag.names["en-US"] || "";
      localeMap.set(localeCode, tagName);
    });
    tagNameMap.set(tag.id, localeMap);
  }
}

// LOOKUP FUNCTIONS FOR SSR METADATA AND BUILD TIME SSG

// object id to short name
export function objectIdToShortName(altId: string): string | null {
  return altIdToIdMap.get(altId) || null;
}

// short name to object id
export function shortNameToObjectId(id: string): string | null {
  return idToAltIdMap.get(id) || null;
}

// short name, locale -> style name
export function shortNameToStyleName(locale: string, id: string): string | null {
  const localeMap = idToNameMap.get(id);
  if (!localeMap) return null;
  return localeMap.get(locale) || null;
}

// object id, locale -> style name
export function objectIdToStyleName(locale: string, objectId: string): string | null {
  const shortName = objectIdToShortName(objectId);
  if (!shortName) return null;
  return shortNameToStyleName(locale, shortName);
}

// tag id, locale -> name
export function getTagName(tagId: string, locale: string): string | null {
  const tagMap = tagNameMap.get(tagId);
  if (!tagMap) return null;

  return tagMap.get(locale) || null;
}

// shortname, locale to style name
export function findStyleNameByIdAndLocale({ id, locale }: { id: string; locale: string }) {
  return shortNameToStyleName(locale, id);
}

// same as above
export const findStyleName = (locale: string, style: string) => {
  return findStyleNameByIdAndLocale({ id: style, locale });
};

// object id to shortname
export function findIdByAltId({ altId }: { altId: string }) {
  return objectIdToShortName(altId);
}

// todo generators should be in context
export function findGeneratorByAltId({ altId }: { altId: string }) {
  const index = altIdToIndexMap.get(altId);
  if (!index) return null;
  return generators[index];
}

// gifReady build-time constant
export const gifReady = (() => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.8porn.ai":
    case "https://www.createporn.com":
    case "https://www.createaiasian.com":
    case "https://www.createaiebony.com":
    case "https://www.createaibbw.com":
    case "https://www.createailatina.com":
    case "https://www.createaiindian.com":
    case "https://www.createaimilf.com":
      return true;
    default:
      return false;
  }
})();

// gifGenerator build-time constant
export const gifGenerator = (() => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.8porn.ai":
    case "https://www.createporn.com":
      return "6795cbf25b8a7b9c59b42e77";
    case "https://www.createaiasian.com":
      return "67af5ffa891d212e31ac1719";
    case "https://www.createaiebony.com":
      return "67af6533891d212e31ac171b";
    case "https://www.createaibbw.com":
      return "67af6b8a891d212e31ac171d";
    case "https://www.createailatina.com":
      return "67af72aa891d212e31ac171f";
    case "https://www.createaiindian.com":
      return "67af75e0891d212e31ac1721";
    case "https://www.createaimilf.com":
      return "67af7c60891d212e31ac1725";
    default:
      return "";
  }
})();
